import docReady from '@/js/helpers/doc-ready'
import { createCookie, readCookie } from '@/js/helpers/cookie'
import { gsap } from 'gsap'

class FormPopup {
  constructor (el) {
    this.el = el
    this.closeEls = el.querySelectorAll('[data-close]')
    this.wrap = el.querySelector('[data-popup-wrap]')

    this.initEvents()
  }

  showPopup () {
    const tl = gsap.timeline()

    tl.set(document.body, {
      overflow: 'hidden'
    })

    tl.to(this.el, {
      duration: 0.6,
      ease: 'expo.out',
      visibility: 'visible',
      opacity: 1
    })

    tl.to(this.wrap, {
      duration: 0.6,
      ease: 'expo.out',
      y: 0,
      opacity: 1
    }, 0)
  }

  closePopup () {
    const tl = gsap.timeline()

    tl.set(document.body, {
      overflow: ''
    })

    tl.to(this.el, {
      duration: 0.4,
      ease: 'expo.out',
      visibility: 'hidden',
      opacity: 0
    })

    tl.to(this.wrap, {
      duration: 0.4,
      ease: 'expo.out',
      y: -50,
      opacity: 0
    }, 0)
  }

  initEvents () {
    const isOpened = readCookie('form-popup-shown')
    if (!isOpened) {
      createCookie('form-popup-shown', true, 42)
      this.showPopup()
    }

    Array.from(this.closeEls).forEach(el => {
      el.addEventListener('click', this.closePopup.bind(this))
    })
  }
}

docReady(() => {
  const formPopupEl = document.querySelector('.form-popup')
  if (formPopupEl) {
    // eslint-disable-next-line
    const formPopup = new FormPopup(formPopupEl)
  }
})
